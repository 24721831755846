import React from 'react'
import styles from './Navigation.module.css';
import { LinkColumnStoryblok } from '@/app/types/component-types-sb';
import Image from '../../globals/image';
import CustomLink from '../../globals/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight } from '@awesome.me/kit-d4c82d9167/icons/classic/solid';
import { useMobileMenu } from '../../globals/navigation/MobileMenuContext';

type Props = {
  item: LinkColumnStoryblok
  showImage: boolean
}

const DropdownGridItem = ({ item, showImage }: Props) => {
  const { toggleMobileMenu } = useMobileMenu();

  const toggleMobileMenuCheck = () => {
    if (typeof window !== 'undefined' && window.innerWidth <= 993) {
      toggleMobileMenu();
    }
  }

  return (
    <>
      {showImage ? (
        <div className={styles.dropDownGridItem}>
          <div className={styles.dropDownFeaturedLabel}>
            {item.featured_link_override_label}
            <FontAwesomeIcon
              style={{
                width: 12,
                height: 12,
                marginTop: 2,
              }}
              color='white'
              icon={faChevronRight as IconProp}
            />
          </div>
          <CustomLink onClick={toggleMobileMenuCheck} link={item.featured_link} storyBlokLink>
            <Image asset={item.featured_link_background_image}
              style={{
                borderRadius: '3px'
              }}
              size={{
                width: 400,
                height: 100
              }} />
          </CustomLink>
        </div>
      ) : (
        <div className={styles.dropdownLinks}>
          {item.links && (
            <>
              {item.links?.map((link) => (
                <CustomLink
                  key={link._uid}
                  image={link.icon}
                  size={{
                    width: 62,
                    height: 20
                  }}
                  override_label={link.override_label}
                  link={link}
                  onClick={toggleMobileMenuCheck}
                  icon={
                    <FontAwesomeIcon
                      style={{
                        width: 12,
                        height: 12,
                        marginTop: 2
                      }}
                      icon={faChevronRight as IconProp}
                    />
                  }
                  className={styles.dropdownLink}
                />
              ))}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default DropdownGridItem