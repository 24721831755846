"use client"

import { Toaster as Sonner, ToasterProps } from "sonner"

const Toaster = ({ ...props }: ToasterProps) => {

  return (
    <Sonner
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            "group toast group-[.toaster]:bg-background group-[.toaster]:text-black group-[.toaster]:text-md group-[.toaster]:border-border group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-black",
          success: "group-[.toaster]:bg-[#0f9960]",
          error: "group-[.toaster]:bg-[#da071b]",
          actionButton:
            "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground font-medium",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground font-medium",
        },
      }}
      {...props}
    />
  )
}

export { Toaster }