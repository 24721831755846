"use client"
import { useRef, useEffect, useState, useCallback } from 'react'
import NavigationParent from './Navigation'
import { MobileMenuContext } from './MobileMenuContext';

type Props = {
  children: React.ReactNode,
}

const NavigationWrapper = ({ children }: Props) => {
  const [isVisible, setIsVisible] = useState(true)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const navigationRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      {
        threshold: 0,
      }
    )

    if (navigationRef.current) {
      observer.observe(navigationRef.current)
    }

    return () => {
      if (navigationRef.current) {
        observer.unobserve(navigationRef.current)
      }
    }
  }, [])

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuOpen(prev => !prev);
  }, []);

  const contextValue = {
    mobileMenuOpen,
    setMobileMenuOpen,
    toggleMobileMenu,
  };

  return (
    <MobileMenuContext.Provider value={contextValue}>
      <div ref={navigationRef} className={mobileMenuOpen ? 'mobileMenuOpen' : 'mobileMenuClosed'}>
        <NavigationParent isVisible={isVisible}>
          {children}
        </NavigationParent>
      </div>
    </MobileMenuContext.Provider>
  )
}

export default NavigationWrapper;