import React from 'react';
import styles from './Navigation.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faXmark } from '@awesome.me/kit-d4c82d9167/icons/classic/solid';
import CustomLink from '../link';
import { InformationTimes } from './Navigation';
import OpeningHours from './OpeningHours';

type Props = {
  setOpenModals: React.Dispatch<React.SetStateAction<boolean>>
  data: InformationTimes | undefined,
  phoneNumber: string | undefined
}

const PhoneNumber = ({ setOpenModals, data, phoneNumber }: Props) => {

  return (
    <div className={styles['contact-card']}>
      <button onClick={() => setOpenModals(false)} className={styles['close-button']}>
        <FontAwesomeIcon icon={faXmark as IconProp} className={styles['close-icon']} />
      </button>

      <div className={styles['contact-grid']}>
        <div className={styles['contact-section']}>
          <section>
            <h2 className={styles['section-title']}>GET IN TOUCH</h2>
            <div className={styles['content-wrapper']}>
              <div className={styles['contact-block']}>
                <h3 className={styles['block-title']}>BOOK A HOLIDAY</h3>
                <OpeningHours openingTimes={data} type="sales" className={'info-text'} />
                <CustomLink href={`tel:${phoneNumber?.replaceAll(/\s/g, '')}`} asButton>
                  CALL {phoneNumber}
                </CustomLink>
              </div>

              <div className={styles['contact-block']}>
                <h3 className={styles['block-title']}>MAKE A PAYMENT</h3>
                <p className={styles['info-text']}>
                  Quickly view, manage, and pay for your holiday online
                </p>
                <CustomLink prefetch={false} href='https://www.haysitour.co.uk/fusion/viewmybooking.pl?site=www.cruisenation.com' asButton>
                  MANAGE BOOKING
                </CustomLink>
              </div>

              <div className={styles['contact-block']}>
                <h3 className={styles['block-title']}>HOLIDAY SUPPORT</h3>
                <p className={styles['info-text']}>
                  Our searchable FAQ has all of the answers you need
                </p>
                <CustomLink href='/knowledge-base' asButton>
                  GET ANSWERS
                </CustomLink>
              </div>

              <div className={styles['contact-block']}>
                <h3 className={styles['block-title']}>CUSTOMER SUPPORT</h3>
                <OpeningHours openingTimes={data} type="support" className={'info-text'} />
                <CustomLink href='tel:01792705301' asButton>
                  CALL 01792 705 301
                </CustomLink>
              </div>
            </div>
          </section>
        </div>

        <div className={styles['hours-section']}>
          <section>
            <h2 className={styles['section-title']}>OPENING TIMES</h2>
            <div className={styles['content-wrapper']}>
              <div className={styles['hours-block']}>
                <h3 className={styles['block-title']}>SALES</h3>
                <table className={styles['hours-table']}>
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Opens</th>
                      <th>Closes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && Object.entries(data.sales).map(([day, value], index) => (
                      <tr key={index}>
                        <td>{day}</td>
                        <td>{value.opening}</td>
                        <td>{value.closing}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className={styles['hours-block']}>
                <h3 className={styles['block-title']}>SUPPORT</h3>
                <table className={styles['hours-table']}>
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Opens</th>
                      <th>Closes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && Object.entries(data.support).map(([day, value], index) => (
                      <tr key={index}>
                        <td>{day}</td>
                        <td>{value.opening}</td>
                        <td>{value.closing}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;