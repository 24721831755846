'use client'
import { LinkColumnStoryblok, NavigationDropdownStoryblok } from '@/app/types/component-types-sb'
import styles from './Navigation.module.css';
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import DropdownGridItem from './DropdownGridItem';
import Image from '@/app/components/globals/image';
import { useClickOutside } from '@/utils/hooks/click-outside';
import Container from '../../globals/container';
import CustomLink from '../../globals/link';
import { faChevronRight } from '@awesome.me/kit-d4c82d9167/icons/classic/regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  dropdown: NavigationDropdownStoryblok
}

const Dropdown = ({ dropdown }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const pathname = usePathname();

  useClickOutside(wrapperRef, () => setIsOpen(false))

  useEffect(() => {
    setIsOpen(false)
  }, [pathname]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    // Check if window width is <= 409px and scroll to button if needed
    if (typeof window !== 'undefined' && window.innerWidth <= 993) {
      // Using requestAnimationFrame to ensure the scroll happens after state update
      requestAnimationFrame(() => {
        const button = document.activeElement;
        if (button) {
          button.scrollIntoView({ block: 'start' });
        }
      });
    }
  };

  return (
    <div>
      <button aria-label={dropdown.label} onClick={toggleDropdown} className={
        clsx(styles.dropdownButton, (isOpen === true) && styles.dropdownButtonSelected)
      }>
        <div className={styles.dropdownLabel}>
          <span>
            <div className={styles.dropdownIcon}>
              <Image asset={dropdown.icon || ''} />
            </div>
            {dropdown.label}
          </span>
          <FontAwesomeIcon icon={faChevronRight as IconProp} />
        </div>
      </button>
      <div
        className={clsx(
          styles.dropdownContainer,
          (isOpen === true) && styles.open
        )}
      >
        <Container>
          <div className={styles.innerContainer} ref={wrapperRef}>
            <p className={styles.dropdownHeader}>{dropdown.header_text} <span>{dropdown.sub_header_text}</span></p>

            <div className={styles.dropDownGrid}>
              {dropdown.link_columns.map((item: LinkColumnStoryblok) => (
                <DropdownGridItem key={item._uid} item={item} showImage={true} />
              ))}
            </div>
            <hr style={{
              marginTop: '30px',
              marginBottom: '30px'
            }} />
            <div className={styles.dropDownGrid}>
              {dropdown.link_columns.map((item: LinkColumnStoryblok) => (
                <DropdownGridItem key={item._uid} item={item} showImage={false} />
              ))}
            </div>
            <hr style={{
              marginTop: '30px',
              marginBottom: '30px'
            }} />
            <CustomLink
              override_label={dropdown.view_all_link_label_override}
              link={dropdown.view_all_link}
              asButton
              storyBlokLink
              variant='primary'
              className={styles.dropdownButtonLink}
            />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Dropdown